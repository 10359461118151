import React from "react";

import TWIITER from "../images/twitter.svg";
import INSTAGRAM from "../images/instagram.svg";
import SNAPCHAT from "../images/snapchat.svg";
import EMAIL from "../images/email.svg";
import DISCORD from "../images/discord.svg";
import MESSAGE from "../images/message.svg";
import ARROW from "../images/arrow_left.svg";

const TAG_LIST = [
	[
		{
			name: "Twitter",
			image: TWIITER,
			link: "https://twitter.com/shackpets",
		},
		{
			name: "Instagram",
			image: INSTAGRAM,
			link: "https://www.instagram.com/shackpets/",
		},
	],
	[
		{
			name: "Snapchat",
			image: SNAPCHAT,
			link: "https://www.snapchat.com/add/shackpets?share_id=RkFGN0FC&locale=en_US",
		},
		{
			name: "Email Us",
			image: EMAIL,
			link: "mailto:support@shackpets.com",
		},
	],
	[
		{
			name: "Discord",
			image: DISCORD,
			link: "https://discord.gg/aAcFjK6c4Z",
		},
		{
			name: "Message Us",
			image: MESSAGE,
			link: "mailto:support@shackpets.com",
		},
	],
];

const Footer = () => {
	return (
		<footer id="contact">
			<h4>contact us</h4>
			<p className="footer-title">Connect with Shackpets!</p>

			<div className="social-wrapper">
				{TAG_LIST.map((tags, index) => (
					<div key={index} className="social-item">
						{tags.map((tag, _index) => (
							<a
								key={_index}
								className="footer-tag"
								href={tag.link}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img src={tag.image} alt={tag.name} />
								<h4 className="footer-tag-text">{tag.name}</h4>
							</a>
						))}
					</div>
				))}
			</div>

			<div className="footer-text">
				<a
					href="https://shacknews.com/pages/tos/"
					target="_blank"
					rel="noopener noreferrer"
					className="footer-text"
				>
					Terms of Service
				</a>{" "}
				•{" "}
				<a
					href="https://shacknews.com/pages/eula/"
					target="_blank"
					rel="noopener noreferrer"
					className="footer-text"
				>
					EULA
				</a>{" "}
				•{" "}
				<a
					href="https://www.shacknews.com/pages/privacy-policy/"
					target="_blank"
					rel="noopener noreferrer"
					className="footer-text"
				>
					Privacy Policy
				</a>{" "}
				• Copyright © 2021 Shackpets
			</div>

			<a
				className="footer-fab"
				href="https://www.shacknews.com"
				target="_blank"
				rel="noopener noreferrer"
			>
				<img src={ARROW} alt="arrow" />
				<span>Back to Shacknews</span>
			</a>
		</footer>
	);
};

export default Footer;
