import React from "react";
import { Link } from "gatsby";

import BRAND_LOGO from "../images/brand_logo.svg";
import APP_STORE from "../images/store/app_store.svg";
import PLAY_STORE from "../images/store/play_store.svg";

const Header = () => {
	return (
		<header>
			<div className="header-logo">
				<img src={BRAND_LOGO} alt="brand_logo" />
			</div>

			<input className="mobile-menu-btn" type="checkbox" id="menu-btn" />
			<label className="mobile-menu-icon" htmlFor="menu-btn">
				<span />
			</label>

			<div className="header-menu">
				<Link className="header-menu-item" to="/#about">
					About
				</Link>
				<Link className="header-menu-item" to="/#faq">
					FAQs
				</Link>
				<Link className="header-menu-item" to="/#contact">
					Contact
				</Link>
				<Link className="header-menu-item" to="/latest">
					Latest Pets
				</Link>

				<a
					className="header-store-menu"
					href="https://apps.apple.com/us/app/shackpets/id1535878997"
					target="_blank"
					rel="noopener noreferrer"
				>
					<img src={APP_STORE} alt="app-store" />
				</a>
				<a
					className="header-store-menu"
					href="https://play.google.com/store/apps/details?id=com.shacknews.shackpets"
					target="_blank"
					rel="noopener noreferrer"
				>
					<img src={PLAY_STORE} alt="play-store" />
				</a>
			</div>
		</header>
	);
};

export default Header;
