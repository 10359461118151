import React from "react";
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from "react-accessible-accordion";

const FAQ_LIST = [
	{
		title: "What is Shackpets?",
		items: [
			{
				content:
					"Shackpets is the first official Shacknews mobile application built on top of our Cortex gamification engine. It will be a destination for pet pictures, and Shackpets allows users to challenge other pets to a battle for cuteness. Vote every day, and you could win Shackpets!",
			},
		],
	},
	{
		title: "Do I need an account to use Shackpets?",
		items: [
			{
				content:
					"Shackpets is powered by Shacknews, and you do need a Shacknews account to use the Shackpets app. You can sign up for a free Shacknews account ",
			},
			{ content: "here.", link: "https://www.shacknews.com/login/register" },
		],
	},
	{
		title: "Is Shackpets free?",
		items: [
			{
				content:
					"Shackpets is free to download and use, but could feature in-app advertisements and optional in-app purchases in the future.",
			},
		],
	},
	{
		title: "How do I delete my Shackpets account?",
		items: [
			{
				content:
					"If you no longer wish to maintain your Shackpets account, the easiest way to remove it is to delete all the pet pictures for each pet you have a profile for. This will prevent your account and photos from being seen and challenged in the future.",
				new_line: true,
			},
			{
				content:
					"A second method to delete your Shackpets account is to delete your Shacknews account, which you must have to use Shackpets. To do this, log into Shacknews ",
			},
			{ content: "here", link: "https://www.shacknews.com/login/login" },
			{ content: ", then click on Account, then click on the option to Delete Account." },
		],
	},
	{
		title: "How do I report a user on Shackpets?",
		items: [
			{
				content:
					"If you’d like to report an account for inappropriate content, you can tap the pet’s name from any photo to get to their profile. The option to report is a flag in the bottom right corner of their profile image. Once you report them, the flag will turn into a golden check, indicating that the profile has been reported.",
			},
		],
	},
	{
		title: "How many photos can I upload?",
		items: [
			{
				content:
					"You can upload as many photos as you wish. Shackpets encourages you to take new pictures of your pets and use them to challenge other pets.",
			},
		],
	},
	{
		title: "How many pets can I create?",
		items: [
			{
				content: "You can create as many pets as you wish while using the Shackpets app.",
			},
		],
	},
	{
		title: "How do I report an offensive photo?",
		items: [
			{
				content:
					"If you see an offensive photo on Shackpets you can report it by tapping on the photo to open it, then tapping the flag in the bottom right corner of the photo.",
			},
		],
	},
	{
		title: "How do I get technical support for Shackpets?",
		items: [
			{
				content:
					"If you don’t see the answer to your question within this FAQ, you can use the Contact Us section at the bottom of this page, or email us at support@shackpets.com.",
			},
		],
	},
	{
		title: "Can I advertise on the Shackpets app?",
		items: [
			{
				content:
					"We’re not fielding offers for advertisement at this time, but when we do you’ll find the instructions right here.",
			},
		],
	},
	{
		title: "What is Shacknews Cortex?",
		items: [
			{
				content:
					"Shacknews Cortex introduced a user-generated content platform for our community and added a gamification engine for interacting with the website. Users can win Shacknews for the day, week, month, and year. Our CEO Lola the Pom declares winners in the Shacknews Carnage Report every day.",
			},
		],
	},
	{
		title: "What is the Carnage Report?",
		items: [
			{
				content:
					"The Shacknews Carnage Report is our official Cortex scoreboard. Users can read this article each day to find out where they ranked for the day, week, month, and year. If you interact with the website enough, you too could win Shacknews!",
			},
		],
	},
	{
		title: "Does Shackpets share my information?",
		items: [
			{
				content:
					"We don’t currently share your information with any third parties. For details on how Shackpets uses your information, please refer to our ",
			},
			{
				content: "privacy policy.",
				link: "https://www.shacknews.com/pages/privacy-policy",
			},
		],
	},
	{
		title: "What is Shacknews?",
		items: [
			{
				content:
					"Shacknews began as a Quake fan site in 1996. We have grown to cover video games and cutting edge technology with evergreen video, guide, and long read content while providing readers and viewers with the latest reviews, previews, interviews, and news. Visit the ",
			},
			{
				content: "Shacknews Front Page.",
				link: "https://www.shacknews.com",
			},
			{
				content: " now.",
			},
		],
	},
	{
		title: "Where did my photo go?",
		items: [
			{
				content:
					"There are many reasons that a photo could be removed from Shackpets, including automatic or human moderation. You can check our ",
			},
			{
				content: "Terms of Service,",
				link: "https://www.shacknews.com/pages/tos",
			},
			{
				content: " or reach out to one of our moderators.",
			},
		],
	},
	{
		title: "How do I upload a photo?",
		items: [
			{
				content: "Please consult ",
			},
			{
				content: "this article",
				link: "https://www.shacknews.com/article/127843/how-to-upload-photos-shackpets",
			},
			{
				content: " for information on uploading photos.",
			},
		],
	},
	{
		title: "How does the challenge screen work?",
		items: [
			{
				content: "Please consult ",
			},
			{
				content: "this article",
				link: "https://www.shacknews.com/article/127845/how-the-challenge-screen-works-shackpets",
			},
			{
				content: " for information on the challenge screen.",
			},
		],
	},
	{
		title: "How do I delete a photo?",
		items: [
			{
				content: "Please consult ",
			},
			{
				content: "this article",
				link: "https://www.shacknews.com/article/127861/how-to-delete-photos-shackpets",
			},
			{
				content: " for information on deleting a photo.",
			},
		],
	},
];

const FAQComp = () => {
	return (
		<div id="faq" className="faq-container">
			<h4>frequently asked questions</h4>
			<p className="faq-title">FAQs</p>

			<div className="accordion-container">
				<Accordion allowZeroExpanded>
					{FAQ_LIST.map((faq, index) => (
						<AccordionItem className="accordion-item" key={index}>
							<AccordionItemHeading>
								<AccordionItemButton className="accordion-item-button">
									<span className="accordion-item-header">{faq.title}</span>
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordion-item-panel">
								{faq.items.map((item, index) => {
									if (item.link) {
										return (
											<a href={item.link} target="_blank" rel="noopener noreferrer" key={index}>
												{item.content}
											</a>
										);
									}

									return (
										<span key={index}>
											{item.content}
											{item.new_line ? (
												<>
													<br />
													<br />
												</>
											) : null}
										</span>
									);
								})}
							</AccordionItemPanel>
						</AccordionItem>
					))}
				</Accordion>
			</div>
		</div>
	);
};

export default FAQComp;
