import React from "react";

const Tutorial = () => {
	return (
		<div id="about" className="tutorial-container">
			<iframe
				className="tutorial-video"
				title="introduce video"
				src="https://www.youtube.com/embed/2z3LVgBD9lM?controls=1"
				allowFullScreen
			></iframe>
		</div>
	);
};

export default Tutorial;
