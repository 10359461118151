import * as React from "react";

import Header from "../components/Header";
import HeroComp from "../components/Hero";
import Faq from "../components/FAQ";
import Footer from "../components/Footer";
import Tutorial from "../components/Tutorial";
import Seo from "../components/SEO";
import "../styles/style.scss";

const HomePage = () => {
	return (
		<>
			<Seo title="Shackpets" />
			<div className="homepage-layout">
				<section className="homepage-section">
					<Header />
					<HeroComp />
				</section>
			</div>
			<section className="homepage-section">
				<Tutorial />
				<Faq />
				<Footer />
			</section>
		</>
	);
};

export default HomePage;
