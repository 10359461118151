import React from "react";

import HERO_IMG from "../images/hero.svg";
import APP_STORE from "../images/store/app_store.svg";
import PLAY_STORE from "../images/store/play_store.svg";

const HeroComp = () => {
	return (
		<div className="hero-container">
			<div className="hero-seo-hidden">
				<h1>Shackpets</h1>
			</div>
			<div className="hero-content">
				<p className="hero-title">The Ultimate</p>
				<p className="hero-title">Battle for</p>
				<p className="hero-title brand">Cuteness</p>

				<h3>Swipe your pet to victory!</h3>

				<div className="store-icon-wrapper">
					<a
						href="https://apps.apple.com/us/app/shackpets/id1535878997"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={APP_STORE} alt="app-store" />
					</a>
					<a
						href="https://play.google.com/store/apps/details?id=com.shacknews.shackpets"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={PLAY_STORE} alt="play-store" />
					</a>
				</div>
			</div>
			<div className="hero-image">
				<img src={HERO_IMG} alt="pet-img" />
			</div>
		</div>
	);
};

export default HeroComp;
